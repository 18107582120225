<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>Site Engineer</h2>
    <form [formGroup]="siteEnggForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="siteEngineer">SiteEngineer Name<span class="requried-field">*</span></label>
          <ng-autocomplete
          [data]="engineerName"
          [searchKeyword]="keyword"
          placeholder="Enter the Product Name"
          (selected)='selectEvent($event)'
          (inputChanged)='onChangeSearch($event)'
          (inputFocused)='onFocused($event)'
          historyIdentifier="siteEngineer"
          [itemTemplate]="itemTemplate1"
          [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>
      
        <ng-template #itemTemplate1 let-item>
          <a [innerHTML]="item.siteEngineer"></a>
        </ng-template>
      
        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
       
          <div *ngIf="submitted && f['siteEngineer'].errors" class="invalid-feedback">
            <div *ngIf="f['siteEngineer'].errors['required']">Name is required</div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="companyName">Company Name<span class="requried-field">*</span></label>
          <select
          id="companyName"
          name="companyName"
          class="form-control"
          formControlName="companyName"
          [ngClass]="{ 'is-invalid': submitted && f['companyName'].errors }"
        >
        <option value="" selected disabled>--Please select--</option>
          <option
            *ngFor="let data of companyName"
            [value]="data"
            >{{ data }}
          </option>
        </select>
          <div *ngIf="submitted && f['companyName'].errors" class="invalid-feedback">
            <div *ngIf="f['companyName'].errors['required']"> Name is required</div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label for="projectName">Project Name<span class="requried-field">*</span></label>
            <ng-autocomplete
            [data]="projectName"
            [searchKeyword]="keyword1"
            placeholder="Enter the  Name"
            (selected)='selectEvent1($event)'
            (inputChanged)='onChangeSearch1($event)'
            (inputFocused)='onFocused1($event)'
            historyIdentifier="projectName"
            [itemTemplate]="itemTemplate2"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
        
          <ng-template #itemTemplate2 let-item>
            <a [innerHTML]="item.projectName"></a>
          </ng-template>
        
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
       
            <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
              <div *ngIf="f['projectName'].errors['required']">Name is required</div>
            </div>
          </div>
          
        <!-- <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="supplierName">Supplier Name<span class="requried-field">*</span></label>
          <input
            type="text"
           
           
            class="form-control"
            formControlName="supplierName"
            [ngClass]="{ 'is-invalid': submitted && f['supplierName'].errors }"
          />
          <div *ngIf="submitted && f['supplierName'].errors" class="invalid-feedback">
            <div *ngIf="f['supplierName'].errors['required']">Supplier Name is required</div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-4">
          <label for="pstatus">Status<span class="requried-field">*</span></label>
          <mat-slide-toggle class="slide-enable" [color]="isEnabled ? ' rgba(0, 128, 0, 0.5) ' : 'default'" (change)="enabled()">Enable</mat-slide-toggle>
         
        </div> -->
      </div>
      <button type="submit" class="btn btn-primary" (click)="onSubmit(siteEnggForm.value)">Submit</button>
      </form>
      </div>