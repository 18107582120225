import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
//import { ToastrModule } from 'ngx-toastr';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule} from '@angular/material/table';
import { AddComponent } from './add/add.component';
import { DataService } from './data.service';
import { NotificationService } from '../../services/notificationService';
import { NgxPaginationModule } from 'ngx-pagination';
import { EditComponent } from './edit/edit.component';
import { SiteEngineerComponent } from './site-engineer.component';
import { SiteEnggRoutingModule } from './siteengg.routing';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

@NgModule({
    declarations: [
        SiteEngineerComponent,
        AddComponent,
        EditComponent
    ],
    providers: [DataService,NotificationService],
    imports: [

        SiteEnggRoutingModule,
        CommonModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        CollapseModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
       // ToastrModule.forRoot(),
        MatListModule,
        MatIconModule,
        MatTableModule,
        MatCardModule,
       // MatPaginator,
        MatPaginatorModule,
        //MatTableDataSource,
        MatProgressBarModule,
        MatGridListModule,
        NgxPaginationModule,
        AutocompleteLibModule
        
       
        
    ]
})
export class SiteEnggModule{ }
