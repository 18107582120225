import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrl: './add.component.scss'
})
export class AddComponent {
  project: any = {};
  companyName: string[] = ['Aura Contrivers','Vasanth Contrivers'];
  siteEnggForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  keyword = 'EngineerName'
  keyword1 ='projectName'
  engineerName:string[]=[]
  projectName:string[]=[]
  isEnabled = false;
  unitName:any;
  isDisabled = false;
  productName:any[]=[];
  constructor(private data: DataService,
    private toastr:NotificationService,
    private formBuilder: FormBuilder,
    private router:Router
  ) {}

  ngOnInit()  {
    this.siteEnggForm= this.formBuilder.group({
      siteEngineer:[''],
      companyName:[''],
      projectName:['']
     
     
    });
  }

 

  goBack() {
    this.router.navigate(['/dashboard/siteEngineer']);
  }
  get f() {
    return this.siteEnggForm.controls;
  }
  onSubmit(data: any) {
    if (this.siteEnggForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.postOn = moment().format();
    data.isActive = true
    
    this.data.create(data).subscribe((res:any)=>{
     console.log(res)
      this.toastr.showSuccess("Successfully!!","Supplier Category Added")
      this.router.navigate(['/dashboard/siteEngineer'])
    })
  }
  selectEvent(item:any) {
    // do something with selected item
    console.log(item)
    this.unitName = item.unitName
   
    this.siteEnggForm.patchValue({
      siteEngineer:item.engineerName
    })
  }
 
  onChangeSearch(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase()
    this.data.getEmployee().subscribe((res:any)=>{
      this.engineerName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.productName)
      return this.engineerName.filter(val => val.toLowerCase().includes(search))

    })
  }

  onFocused(e:any) {
    console.log(e)
    // do something
  }
  selectEvent1(item:any) {
    // do something with selected item
    console.log(item)
    this.unitName = item.unitName
   
    this.siteEnggForm.patchValue({
      siteEngineer:item.engineerName
    })
  }
 
  onChangeSearch1(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase()
    this.data.getProduct().subscribe((res:any)=>{
      this.projectName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.productName)
      return this.projectName.filter(val => val.toLowerCase().includes(search))

    })
  }

  onFocused1(e:any) {
    console.log(e)
    // do something
  }
}
