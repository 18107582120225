import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  project: any = {};
  companyName: string[] = ['Aura Contrivers','Vasanth Contrivers'];
  siteEnggForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  keyword = 'EngineerName'
  keyword1 ='projectName'
  engineerName:string[]=[]
  projectName:string[]=[]
  isEnabled = false;
  unitName:any;
  isDisabled = false;
  productName:any[]=[];
  id:any;

  constructor(private data: DataService,
    private toastr:NotificationService,
    private formBuilder: FormBuilder,
    private router:Router,
    private route:ActivatedRoute
  ) {}

  ngOnInit()  {
    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }

    this.siteEnggForm= this.formBuilder.group({
      siteEngineer:[''],
      companyName:[''],
      projectName:['']
     
     
    });
  }

  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.siteEnggForm.patchValue({
     siteEngineer:data.siteEngineer,
     companyName:data.companyName,
     projectName:data.projectName
    })
  }
 

  goBack() {
    this.router.navigate(['/dashboard/siteEngineer']);
  }
  get f() {
    return this.siteEnggForm.controls;
  }
  onSubmit(data: any) {
    if (this.siteEnggForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.updateOn = moment().format();
    //data.isActive = true
    
    this.data.update(data,this.id).subscribe((res:any)=>{
     console.log(res)
      this.toastr.showSuccess("Successfully!!","Supplier Category Added")
      this.router.navigate(['/dashboard/siteEngineer'])
    })
  }
  selectEvent(item:any) {
    // do something with selected item
    console.log(item)
    this.unitName = item.unitName
   
    this.siteEnggForm.patchValue({
      siteEngineer:item.engineerName
    })
  }
 
  onChangeSearch(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase()
    this.data.getEmployee().subscribe((res:any)=>{
      this.engineerName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.productName)
      return this.engineerName.filter(val => val.toLowerCase().includes(search))

    })
  }

  onFocused(e:any) {
    console.log(e)
    // do something
  }
  selectEvent1(item:any) {
    // do something with selected item
    console.log(item)
    this.unitName = item.unitName
   
    this.siteEnggForm.patchValue({
      siteEngineer:item.engineerName
    })
  }
 
  onChangeSearch1(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase()
    this.data.getProduct().subscribe((res:any)=>{
      this.projectName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.productName)
      return this.projectName.filter(val => val.toLowerCase().includes(search))

    })
  }

  onFocused1(e:any) {
    console.log(e)
    // do something
  }
}
